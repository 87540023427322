import React, { FunctionComponent } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "../Link";
// @ts-ignore
import BlockContent from "@sanity/block-content-to-react";
import sanityImageUrlBuilder from "../../utils/imageUrlBuilder";
import { useSiteMetadata } from "../../hooks/useSiteMetadata";
import { ImageInterface, PortableText, SanityColorList, Slug } from "../../types/SanityTypes";
import { ColorVariations } from "../../types/ComponentTypes";
import "./styles.scss";

export interface ArticleSingleTeaserInterface {
  ctaReference: {
    _id: string;
    _ref?: string;
    teaser?: {
      teaserHeadline: string;
      teaserCTALabel?: string;
      _rawTeaserText: PortableText;
      _rawTeaserHeroImage: ImageInterface;
      // For Preview
      teaserText?: PortableText;
      teaserHeroImage?: ImageInterface;
    };
    slug?: {
      current: string;
    };
    parentSection: Slug & {
      main: {
        sectionColor: SanityColorList;
      };
      parentSection?: Slug & {
        main: {
          sectionColor: SanityColorList;
        };
      };
    };
  };
  articleSingleTeaserBlockType: {
    name: string;
  };
  imageAlignment: string;
  headingOverride?: boolean;
}

const ArticleSingleTeaser: FunctionComponent<ArticleSingleTeaserInterface> = ({
  ctaReference,
  articleSingleTeaserBlockType,
  imageAlignment,
  headingOverride
}) => {
  const { sanityId, sanityDataset } = useSiteMetadata();
  const urlBuilder = sanityImageUrlBuilder({
    projectId: sanityId,
    dataset: sanityDataset
  });

  const colorVariations: ColorVariations = {
    Purple: {
      primaryGradientColor: "var(--purple-light-color)",
      secondaryGradientColor: "var(--pink-light-color)",
      gradientDegrees: "10"
    },
    Red: {
      primaryGradientColor: "var(--orange-color)",
      secondaryGradientColor: "var(--pink-medium-color)",
      gradientDegrees: "300"
    },
    Teal: {
      primaryGradientColor: "var(--get-mentored-green-color)",
      secondaryGradientColor: "var(--get-mentored-blue-color)",
      gradientDegrees: "225"
    },
    Pink: {
      primaryGradientColor: "var(--pink-bright-color)",
      secondaryGradientColor: "var(--pink-bright-color)",
      gradientDegrees: "-146"
    },
    Blue: {
      primaryGradientColor: "var(--blue-dark-color)",
      secondaryGradientColor: "var(--blue-bright-color)",
      gradientDegrees: "90"
    },
    Primary: {
      primaryGradientColor: "var(--primary-color)",
      secondaryGradientColor: "var(--primary-color)",
      gradientDegrees: "90"
    }
  };

  const parentAttributes = ctaReference.parentSection?.parentSection
    ? {
        colorTheme: ctaReference.parentSection.parentSection.main?.sectionColor.title as string,
        colorThemeValue: ctaReference.parentSection.parentSection.main?.sectionColor.value,
        parentPath: `${ctaReference.parentSection.parentSection.slug?.current}/${ctaReference.parentSection?.slug?.current}`
      }
    : {
        colorTheme: ctaReference.parentSection?.main?.sectionColor.title as string,
        colorThemeValue: ctaReference.parentSection?.main?.sectionColor.value,
        parentPath: ctaReference?.parentSection?.slug?.current
      };

  const heroImage = ctaReference.teaser?._rawTeaserHeroImage || ctaReference.teaser?.teaserHeroImage;
  const text = ctaReference.teaser?._rawTeaserText || ctaReference.teaser?.teaserText;
  const arrangementOrder = imageAlignment === "Left" ? "image-left" : "image-right";

  const tripleHeadlineText = (
    <>
      <span aria-hidden="true">{ctaReference.teaser?.teaserHeadline}</span>
      {ctaReference.teaser?.teaserHeadline}
      <span aria-hidden="true">{ctaReference.teaser?.teaserHeadline}</span>
    </>
  );

  const tripleHeadline = (
    <div className={`triple-headline ${arrangementOrder}`}>
      <Row>
        <Col lg={{ span: 5 }} className="image-box">
          {heroImage && (
            <picture
              className="placeholder"
              style={
                heroImage.asset.metadata
                  ? {
                      paddingTop: `calc(32% / ${heroImage.asset.metadata.dimensions.aspectRatio})`,
                      paddingBottom: `calc(28% / ${heroImage.asset.metadata.dimensions.aspectRatio})`,
                      background: `url(${heroImage.asset.metadata.lqip})`,
                      backgroundSize: "cover"
                    }
                  : undefined
              }
            >
              <source
                media={"(min-width: 1200px)"}
                srcSet={
                  urlBuilder.image(heroImage).auto("format").quality(80).width(700).height(900).format("webp").url() ||
                  undefined
                }
              />
              <source
                media={"(min-width: 768px)"}
                srcSet={
                  urlBuilder.image(heroImage).auto("format").quality(80).width(500).height(700).format("webp").url() ||
                  undefined
                }
              />
              <img
                src={
                  urlBuilder.image(heroImage).auto("format").quality(80).width(500).height(700).format("webp").url() ||
                  undefined
                }
                alt={heroImage.alt}
                className="img-fluid img-center"
                loading={"lazy"}
              />
            </picture>
          )}
        </Col>
        <Col lg={{ span: 1, order: 2 }} aria-hidden="true" />
        <Col lg={{ span: 6 }} className="text-box">
          {headingOverride ? (
            <h1 className="subhead" style={{ color: parentAttributes.colorThemeValue }}>
              {tripleHeadlineText}
            </h1>
          ) : (
            <h2 className="subhead" style={{ color: parentAttributes.colorThemeValue }}>
              {tripleHeadlineText}
            </h2>
          )}
          {text && <BlockContent blocks={text} />}
          {ctaReference.teaser?.teaserCTALabel && ctaReference.slug && (
            <Link
              _id={ctaReference._id}
              to={`${parentAttributes.parentPath}/${ctaReference.slug?.current}`}
              className="btn-primary"
              data-analytics-event4=""
            >
              {ctaReference.teaser?.teaserCTALabel}
            </Link>
          )}
        </Col>
      </Row>
    </div>
  );

  const boxColorBorder = (
    <div className={`box-border-color ${arrangementOrder}`}>
      <style>
        {`
          .bordered-box:after {
            border: 3px solid ${parentAttributes.colorThemeValue};
          }
          @media (max-width: 991px) {
            .bordered-box {
              border: 3px solid ${parentAttributes.colorThemeValue};
            }
          }
        `}
      </style>
      <Row>
        <Col lg={8} className="image-box">
          {heroImage && (
            <picture
              className="placeholder"
              style={
                heroImage.asset.metadata
                  ? {
                      paddingTop: "30%",
                      paddingBottom: "30%",
                      background: `url(${heroImage.asset.metadata.lqip})`,
                      backgroundSize: "cover"
                    }
                  : undefined
              }
            >
              <source
                media={"(min-width: 1200px)"}
                srcSet={
                  urlBuilder.image(heroImage).auto("format").quality(80).width(1000).height(660).format("webp").url() ||
                  undefined
                }
              />
              <source
                media={"(min-width: 700px)"}
                srcSet={
                  urlBuilder.image(heroImage).auto("format").quality(80).width(960).height(634).format("webp").url() ||
                  undefined
                }
              />
              <img
                src={
                  urlBuilder.image(heroImage).auto("format").quality(80).width(700).height(462).format("webp").url() ||
                  undefined
                }
                alt={heroImage.alt}
                className="img-fluid"
                loading={"lazy"}
              />
            </picture>
          )}
        </Col>
        <Col lg={{ span: 4 }} sm={{ span: 10 }} className="text-box">
          <div className="bordered-box">
            <h2 className="subhead" style={{ color: parentAttributes.colorThemeValue }}>
              {ctaReference.teaser?.teaserHeadline}
            </h2>
            {text && <BlockContent blocks={text} />}
            {ctaReference.teaser?.teaserCTALabel && ctaReference.slug && (
              <Link
                _id={ctaReference._id}
                to={`${parentAttributes.parentPath}/${ctaReference.slug?.current}`}
                className="btn-primary"
                data-analytics-event4=""
              >
                {ctaReference.teaser?.teaserCTALabel}
              </Link>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );

  const boxGradient = `linear-gradient(${colorVariations[parentAttributes.colorTheme]?.primaryGradientColor}, ${
    colorVariations[parentAttributes.colorTheme]?.secondaryGradientColor
  }),
  linear-gradient(${colorVariations[parentAttributes.colorTheme]?.secondaryGradientColor}, ${
    colorVariations[parentAttributes.colorTheme]?.secondaryGradientColor
  })`;

  const boxPopOut = (
    <>
      <style>
        {`
          .box-pop-out-background {
            background-image: linear-gradient(${colorVariations[parentAttributes.colorTheme]?.primaryGradientColor}, ${
          colorVariations[parentAttributes.colorTheme]?.primaryGradientColor
        });
          }
          @media (min-width: 992px) {
            .box-pop-out-background {
              background-image: linear-gradient(${colorVariations[parentAttributes.colorTheme]?.primaryGradientColor},${
          colorVariations[parentAttributes.colorTheme]?.secondaryGradientColor
        }),
              linear-gradient(${colorVariations[parentAttributes.colorTheme]?.secondaryGradientColor}, $${
          colorVariations[parentAttributes.colorTheme]?.secondaryGradientColor
        });
            }
          }
        `}
      </style>
      <Row
        noGutters
        className={`box-pop-out align-items-stretch ${arrangementOrder}`}
        style={{ backgroundImage: boxGradient }}
      >
        <Col lg={6} className="image-box align-self-center">
          {heroImage && (
            <picture
              className="placeholder"
              style={
                heroImage.asset.metadata
                  ? {
                      paddingTop: `calc(45% / ${heroImage.asset.metadata.dimensions.aspectRatio})`,
                      paddingBottom: `calc(45% / ${heroImage.asset.metadata.dimensions.aspectRatio})`,
                      background: `url(${heroImage.asset.metadata.lqip})`,
                      backgroundSize: "cover"
                    }
                  : undefined
              }
            >
              <source
                media={"(min-width: 1200px)"}
                srcSet={
                  urlBuilder.image(heroImage).auto("format").quality(80).width(1000).height(800).format("webp").url() ||
                  undefined
                }
              />
              <source
                media={"(min-width: 768px)"}
                srcSet={
                  urlBuilder.image(heroImage).auto("format").quality(80).width(700).height(600).format("webp").url() ||
                  undefined
                }
              />
              <img
                src={
                  urlBuilder.image(heroImage).auto("format").quality(80).width(500).height(400).format("webp").url() ||
                  undefined
                }
                alt={heroImage.alt}
                className="img-fluid img-center"
                loading={"lazy"}
              />
            </picture>
          )}
        </Col>
        <Col lg={6} className="text-box" style={{ borderColor: parentAttributes.colorThemeValue }}>
          <div className="text-box-2">
            <h2 className="subhead" style={{ color: parentAttributes.colorThemeValue }}>
              {ctaReference.teaser?.teaserHeadline}
            </h2>
            {text && <BlockContent blocks={text} />}
            {ctaReference.teaser?.teaserCTALabel && ctaReference.slug && (
              <Link
                _id={ctaReference._id}
                to={`${parentAttributes.parentPath}/${ctaReference.slug?.current}`}
                className="btn-primary"
                data-analytics-event4=""
              >
                {ctaReference.teaser?.teaserCTALabel}
              </Link>
            )}
          </div>
        </Col>
      </Row>
    </>
  );

  const renderDoubleTeaser = () => {
    if (articleSingleTeaserBlockType.name === "Triple Headline") return tripleHeadline;
    if (articleSingleTeaserBlockType.name === "Box Colour Border") return boxColorBorder;
    if (articleSingleTeaserBlockType.name === "Box Pop Out") return boxPopOut;
  };

  return (
    <section data-testid="article-single-teaser" className="article-single-teaser">
      <Container fluid>{renderDoubleTeaser()}</Container>
    </section>
  );
};

export default ArticleSingleTeaser;
